import React, { useEffect, useState, useMemo } from 'react';
import { Tabs, Tab, Container, Table, Button, Spinner } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import useAuth from '../../Hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/releases-page.css';

const RELEASES_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const cognitoAdminGroup = process.env.REACT_APP_COGNITO_ADMIN_GROUP;

function ReleasesPage() {
    const [releases, setReleases] = useState([]);
    const [codeFreezeState, setCodeFreezeState] = useState("");
    const [isLoadingReleases, setIsLoadingReleases] = useState(false);
    const [isLoadingCodeFreezeState, setIsLoadingCodeFreezeState] = useState(false);

    const { tokens, loading } = useAuth();
    const { accessToken: { payload: { "cognito:groups": groups = [] } = {} } = {} } = tokens || {};
    const isAdmin = [cognitoAdminGroup].some(group => groups.includes(group));

    const axiosInstance = useMemo(() => axios.create({
        baseURL: RELEASES_API_ENDPOINT,
        headers: {
            'Authorization': `Bearer ${tokens.accessToken}`
        },
    }), [tokens.accessToken]);

    // useEffect(() => {

    //     if (!loading) {
    //         const fetchData = async () => {
    //             try {
    //                 const releasesResponse = await axiosInstance.get('/releases');
    //                 let releasesSorted = releasesResponse.data.sort((a, b) => a.repository.localeCompare(b.repository))
    //                 setReleases(releasesSorted);

    //                 const codeFreezeState = await axiosInstance.get('/code_freeze/state');
    //                 setCodeFreezeState(codeFreezeState);
    //             }
    //             catch (error) {
    //                 console.error('Error fetching data', error);
    //             }
    //         };
    //         fetchData();
    //     }

    // }, [loading, axiosInstance]);

    useEffect(() => {
        const fetchData = async () => {
            if (loading) return;

            try {
                const releasesResponse = await axiosInstance.get('/releases');
                let releasesSorted = releasesResponse.data.sort((a, b) => a.repository.localeCompare(b.repository))
                setReleases(releasesSorted);

                const codeFreezeState = await axiosInstance.get('/code_freeze/state');
                setCodeFreezeState(codeFreezeState);
            }
            catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [loading, tokens, axiosInstance]);


    const updateReleases = async () => {
        setIsLoadingReleases(true);
        try {
            const response = await axiosInstance.post('/releases');
            console.log(response)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoadingReleases(false);
        }
    };

    const updateCodeFreezeState = async (mode) => {
        setIsLoadingCodeFreezeState(true);
        try {
            const response = await axiosInstance.post('/code_freeze/state', { codeFreezeMode: mode });
            console.log(response)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoadingCodeFreezeState(false);
        }
    };

    return (
        <Tabs
            defaultActiveKey="releases"
            id="releases-tabs"
            className="mb-3"
        >
            <Tab eventKey="releases" title="Releases">
                <Container className="text-center">
                    <h2 className="table-title">Releases</h2>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Repository</th>
                                <th>SIT</th>
                                <th>PPRD</th>
                                <th>PROD</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                releases.map((release, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {release.projectLink ? (
                                                <a href={release.projectLink} target="_blank" rel="noopener noreferrer">
                                                    {release.repository}
                                                </a>
                                            ) : (
                                                <span>{release.repository}</span>
                                            )}
                                        </td>
                                        <td>{release.sit}</td>
                                        <td>
                                            {release.projectLink ? (
                                                <a
                                                    href={`${release.projectLink}/commits/${release.pprd}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {release.pprd}
                                                </a>
                                            ) : (
                                                release.pprd
                                            )}
                                        </td>
                                        <td>{release.prod}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    {
                        isAdmin && (
                            <div className="mb-2 d-flex justify-content-start">
                                <Button onClick={updateReleases} disabled={isLoadingReleases} variant="primary">
                                    {isLoadingReleases ? 'Updating...' : 'Update releases'}
                                </Button>
                            </div>
                        )
                    }
                </Container>
            </Tab>
            {/* <Tab eventKey="taskChecker" title="Release deployment task checker">
                {
                    <Container className="text-center">
                        <h2 className="table-title">Release deployment task checker</h2>
                    </Container>
                }
            </Tab> */}
            <Tab eventKey="codeFreeze" title="Code freeze">
                <Container className="text-center">
                    <h2>Code freeze</h2>
                    {
                        isAdmin ? (
                            <>
                                <div>Current status: <b>{codeFreezeState.data}</b></div>
                                <br />
                                <Form>
                                    <Button variant="dark" type="submit" onClick={() => updateCodeFreezeState(codeFreezeState.data === "Enabled" ? "disable" : "enable")}
                                        disabled={isLoadingCodeFreezeState || codeFreezeState.data === "Updating"}
                                    >
                                        {isLoadingCodeFreezeState || codeFreezeState.data === "Updating" ? (
                                            <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                &nbsp; Updating...
                                            </>
                                        ) : (
                                            codeFreezeState.data === "Enabled" ? "Disable code freeze" : "Enable code freeze"
                                        )}
                                    </Button>
                                </Form>
                            </>
                        ) : (
                            <div>Current status: <b>{codeFreezeState.data}</b></div>
                        )
                    }
                </Container>
            </Tab>
        </Tabs >
    )
}

export default ReleasesPage;